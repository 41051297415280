<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ currentLocale == 'bn' ? headerText.text_bn : headerText.text_en }}</h2>
      <img src="../../../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item"><a href="javascript:" @click="$router.go(-1)">{{ $t('portal.service_details') }}</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('elearning_tpm.ac_training_calendar') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tpm.ac_training_calendar')}}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="4">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('globalTrans.fiscal_year')"
                    label-for="fiscal_year_id"
                    >
                    <b-form-select
                        plain
                        v-model="search.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
        <b-col md="12" v-if="showData">
          <b-overlay :show="loading">
            <b-row v-if='datas.length'>
              <b-col md="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('elearning_tpm.training_calendar') }}</h4>
                  </template>
                  <template v-slot:headerAction>
                      <b-button variant="primary btn_add_new" @click="pdfExport" class="mr-2">
                        <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                      </b-button>
                      <!-- <b-button variant="primary btn_add_new" @click="wordExport" class="mr-2">
                        <i class="fas fa-print"></i>{{  $t('globalTrans.word') }}
                      </b-button> -->
                  </template>
                  <template v-slot:body>
                    <b-overlay :show="loader">
                      <b-row mt-5>
                        <b-col md="12" class="table-responsive">
                          <div style="border: 2px solid;margin:10px;padding:10px">
                            <b-row>
                              <b-col>
                                <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                  <template v-slot:projectNameSlot>
                                    {{ }}
                                  </template>
                                  <span v-if="search.org_id === 12">{{ $t('elearning_tpm.ac_training_calendar') }} {{ '(' }}{{ ($i18n.locale==='bn') ? searchHeaderData.fiscal_year_bn : searchHeaderData.fiscal_year_en }}{{ ')' }}</span>
                                  <span v-else>{{ $t('elearning_tpm.training_calendar') }} {{ '(' }}{{ ($i18n.locale==='bn') ? searchHeaderData.fiscal_year_bn : searchHeaderData.fiscal_year_en }}{{ ')' }}</span>
                                </list-report-head>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <div class="text-center">
                                </div>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="datas.length">
                                  <b-thead>
                                    <b-tr>
                                      <b-th style="width:1%">{{$t('globalTrans.sl_no')}}</b-th>
                                      <b-th style="width:1%">{{$t('elearning_config.training_title')}}</b-th>
                                      <b-th style="width:1%">{{ $i18n.locale === 'en' ? 'Days' : 'দিন' }}</b-th>
                                      <b-th style="width:1%">{{ $i18n.locale === 'en' ? 'No of Batch' : 'ব্যাচ সংখ্যা' }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Jul/' : 'জুলাই/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Aug/' : 'আগস্ট/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Sep/' : 'সেপ্টে/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Oct/' : 'অক্টো/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Nov/' : 'নভে/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Dec/' : 'ডিসে/' }}{{ fiscalYearStart() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Jan/' : 'জানু/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Feb/' : 'ফেব্রু/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Mar/' : 'মার্চ/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Apr/' : 'এপ্রিল/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'May/' : 'মে/' }}{{ fiscalYearEnd() }}</b-th>
                                      <b-th style="width:8%">{{ $i18n.locale === 'en' ? 'Jun/' : 'জুন/' }}{{ fiscalYearEnd() }}</b-th>
                                    </b-tr>
                                  </b-thead>
                                  <b-tbody>
                                    <b-tr v-for="(data, index) in datas" :key="index">
                                      <b-td class="text-center" style="font-size: 10px">
                                        {{ $n(index + 1) }}
                                      </b-td>
                                      <b-td style="font-size: 10px">
                                        {{ getTrainingTitle(data.training_title_id) }}
                                      </b-td>
                                      <b-td class="text-center" style="font-size: 10px">
                                        {{ $n( countDay(data.training_title_id)) }}
                                      </b-td>
                                      <b-td class="text-center" style="font-size: 10px">
                                        {{ $n( getBatch(data.training_title_id)) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 6)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 6) }}
                                        {{ durationEnd(data.id, data.training_title_id, 6) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 6) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 7)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 7) }}
                                        {{ durationEnd(data.id, data.training_title_id, 7) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 7) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 8)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 8) }}
                                        {{ durationEnd(data.id, data.training_title_id, 8) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 8) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 9)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 9) }}
                                        {{ durationEnd(data.id, data.training_title_id, 9) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 9) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 10)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 10) }}
                                        {{ durationEnd(data.id, data.training_title_id, 10) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 10) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 11)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 11) }}
                                        {{ durationEnd(data.id, data.training_title_id, 11) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 11) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 0)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 0) }}
                                        {{ durationEnd(data.id, data.training_title_id, 0) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 0) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 1)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 1) }}
                                        {{ durationEnd(data.id, data.training_title_id, 1) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 1) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 2)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 2) }}
                                        {{ durationEnd(data.id, data.training_title_id, 2) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 2) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 3)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 3) }}
                                        {{ durationEnd(data.id, data.training_title_id, 3) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 3) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 4)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 4) }}
                                        {{ durationEnd(data.id, data.training_title_id, 4) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 4) }}
                                      </b-td>
                                      <b-td class="text-center" v-if="(calculateColspan(data.training_title_id, 5)) > 1">
                                        {{ durationStart(data.id, data.training_title_id, 5) }}
                                        {{ durationEnd(data.id, data.training_title_id, 5) }}
                                      </b-td>
                                      <b-td class="text-center" v-else>
                                        {{ duration(data.id, data.training_title_id, 5) }}
                                      </b-td>
                                    </b-tr>
                                  </b-tbody>
                                </b-table-simple>
                              </b-col>
                            </b-row>
                            <b-row class=" mt-5">
                              <b-col sm="8"></b-col>
                              <b-col class="text-center" sm="4">
                                {{ $i18n.locale==='en' ? directorGenerialData.name : directorGenerialData.name_bn }}<br>
                                {{ $i18n.locale==='en' ? directorGenerialData.designation_name : directorGenerialData.designation_name_bn }}<br>
                                {{ $i18n.locale==='en' ? 'NATA, Gazipur' : 'নাটা, গাজীপুর' }}
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col class="text-center mt-3" v-if="$i18n.locale==='bn' && datas.length > 0"><strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer1') }} {{ $t('tpm_report.report_footer2') }}</b-col>
                              <b-col class="text-center mt-3" v-if="$i18n.locale==='en' && datas.length > 0">{{ $t('tpm_report.report_footer1') }} <strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer2') }}</b-col>
                            </b-row>
                          </div>
                        </b-col>
                      </b-row>
                    </b-overlay>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
      </b-container>
    </div>
    <b-modal ref="mymodalss" id="modal-4560" size="sm" :title="$t('globalTrans.type')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <b-col lg="12">
          <b-overlay :show="loading">
            <div style="height: 100px">
              <b-form-group>
                <template v-slot:label>
                  <br/>
                </template>
                <b-form-radio-group
                  v-model="training_type"
                  :options="trainingType"
                  stacked
                  class="mb-3"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </b-overlay>
        </b-col>
      </b-modal>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
// import { EventBus } from '@/EventBusLayout'
const today = new Date().toISOString().substr(0, 10)

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
        return {
          org_id: this.$route.meta.org_id,
          training_type: 0,
          headerText: { text_en: '', text_bn: '' },
          service: {},
          search: {
            org_id: 12,
            fiscal_year_id: 0
          },
          searchHeaderData: {
            fiscal_year_en: '',
            fiscal_year_bn: '',
            org_en: '',
            org_bn: ''
          },
          certiificateList: [],
          id: 0,
          item: '',
          applicationDeadline: today,
          loadingData: true,
          loading: false,
          appliedList: [],
          datas: [],
          directorGenerialData: {},
          tmpFiscalYearList: [],
          fiscalYearList: []
        }
    },
    watch: {
      training_type: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal !== 0) {
            this.$store.commit('ExternalUserIrrigation/setTrainingPanel', {
              trainingType: newVal,
              trainingOrg: this.org_id
            })
            this.$router.push('/auth/login')
          }
        }
      },
      currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fiscalYearList = this.fiscalYearLocale(this.tmpFiscalYearList)
        }
      }
    },
    computed: {
      batchList () {
        const batchList = [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
        return batchList
      },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      },
      monthList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Jan' : 'জানু', text_en: 'Jan', text_bn: 'জানু' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Feb' : 'ফেব্রু', text_en: 'Feb', text_bn: 'ফেব্রু' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Mar' : 'মার্চ', text_en: 'Mar', text_bn: 'মার্চ' },
          { value: 4, text: this.$i18n.locale === 'en' ? 'Apr' : 'এপ্রিল', text_en: 'Apr', text_bn: 'এপ্রিল' },
          { value: 5, text: this.$i18n.locale === 'en' ? 'May' : 'মে', text_en: 'May', text_bn: 'মে' },
          { value: 6, text: this.$i18n.locale === 'en' ? 'Jun' : 'জুন', text_en: 'Jun', text_bn: 'জুন' },
          { value: 7, text: this.$i18n.locale === 'en' ? 'Jul' : 'জুলাই', text_en: 'Jul', text_bn: 'জুলাই' },
          { value: 8, text: this.$i18n.locale === 'en' ? 'Aug' : 'আগস্ট', text_en: 'Aug', text_bn: 'আগস্ট' },
          { value: 9, text: this.$i18n.locale === 'en' ? 'Sep' : 'সেপ্টে', text_en: 'Sep', text_bn: 'সেপ্টে' },
          { value: 10, text: this.$i18n.locale === 'en' ? 'Oct' : 'অক্টো', text_en: 'Oct', text_bn: 'অক্টো' },
          { value: 11, text: this.$i18n.locale === 'en' ? 'Nov' : 'নভে', text_en: 'Nov', text_bn: 'নভে' },
          { value: 12, text: this.$i18n.locale === 'en' ? 'Dec' : 'ডিসে', text_en: 'Dec', text_bn: 'ডিসে' }
        ]
      },
      trainingType: function () {
        return [
            { value: 1, text: this.$i18n.locale === 'en' ? 'Resource Person' : 'রিসোর্স পারসন' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী' }
        ]
      },
      ...mapGetters({
          authUser: 'Auth/authUser'
      }),
      currentLocale () {
        return this.$i18n.locale
      },
      accommodationList () {
          const accommodations = [
              { text: this.$i18n.locale === 'en' ? 'With Accommodation' : 'থাকার ব্যবস্থা সহ', text_en: 'With Accommodation', text_bn: 'থাকার ব্যবস্থা সহ', value: 1 },
              { text: this.$i18n.locale === 'en' ? 'Without Accommodation' : 'থাকার ব্যবস্থা ছাড়া', text_en: 'Without Accommodation', text_bn: 'থাকার ব্যবস্থা ছাড়া', value: 2 }
          ]
          return accommodations
      },
      // fiscalYearList: function () {
      //   return this.$store.state.Portal.commonObj.fiscalYearList.filter(item => item.status === 0)
      // },
      orgList: function () {
        return this.$store.state.Portal.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.Portal.traineeTranerObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('externalTraining.training_application')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-left' },
              { label: this.$t('elearning_config.fiscal_year'), class: 'text-left' },
              { label: this.$t('globalTrans.category'), class: 'text-left' },
              { label: this.$t('globalTrans.title'), class: 'text-left' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-left' },
              // { label: this.$t('elearning_iabm.application_deadline'), class: 'text-left' },
              { label: this.$t('globalTrans.start_date'), class: 'text-left' },
              { label: this.$t('globalTrans.end_date'), class: 'text-left' },
              // { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'circular_memo_no' },
              { key: 'fiscal_year_bn' },
              { key: 'training_category_bn' },
              { key: 'training_title_bn' },
              { key: 'batch_name_bn' },
              // { key: 'application_deadline' },
              { key: 'training_start_date' },
              { key: 'training_end_date' },
              // { key: 'status' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'circular_memo_no' },
              { key: 'fiscal_year' },
              { key: 'training_category' },
              { key: 'training_title_en' },
              { key: 'batch_name' },
              // { key: 'application_deadline' },
              { key: 'training_start_date' },
              { key: 'training_end_date' },
              // { key: 'status' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
      this.getFiscalYearList()
      this.searchData()
    },
    mounted () {
        core.index()
    },
    methods: {
      async pdfExport () {
        this.loader = true
        const obj = {
          role: this.$store.state.Auth.activeRoleId,
          auth_org: 12
        }
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id,
          org_id: 12,
          name: this.directorGenerialData.name,
          name_bn: this.directorGenerialData.name_bn,
          designation_name: this.directorGenerialData.designation_name,
          designation_name_bn: this.directorGenerialData.designation_name_bn
          // training_type_id: this.search.training_type_id,
          // training_category_id: this.search.training_category_id,
          // training_title_id: this.search.training_title_id
        }
        const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
          const customItem = {
            fiscal_year_en: fiscalYear?.text_en,
            fiscal_year_bn: fiscalYear?.text_bn
          }
        const searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale }, obj, serchData, searchHeaderData)
        const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, '/portal/training-calender/training-calender-pdf', params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        this.loader = false
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
      },
      async wordExport () {
        this.loader = true
        const obj = {
            role: this.$store.state.Auth.activeRoleId,
            auth_org: 12
        }
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id,
          org_id: 12
          // training_type_id: this.search.training_type_id,
          // training_category_id: this.search.training_category_id,
          // training_title_id: this.search.training_title_id
        }
        const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
          const customItem = {
            fiscal_year_en: fiscalYear?.text_en,
            fiscal_year_bn: fiscalYear?.text_bn
          }
        const searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
        const params = Object.assign({ request_type: 'word', local: this.$i18n.locale }, obj, serchData, searchHeaderData)
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

        try {
          const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, '/portal/training-calender/training-calender-word', params)

          // Create a Blob and object URL
          var blob = new Blob([result], {
            type: 'application/msword' // Use 'application/msword' for Word documents
          })
          var url = window.URL.createObjectURL(blob)

          // Create a temporary link element to trigger the download
          var a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = 'training_calender.docx' // Set the desired filename with .docx extension for Word documents
          this.loader = false
          // Trigger the download and cleanup
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        } catch (error) {
          // Handle any errors here
        } finally {
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      },
      countDay (id) {
        const data = this.datas.find(item => item.training_title_id === id)
        const startDate = new Date(data.training_start_date)
        const endDate = new Date(data.training_end_date)
        endDate.setDate(endDate.getDate() + 1)
        const differenceInMs = endDate - startDate
        const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24)
        return differenceInDays
      },
      getTrainingTitle (id) {
        const trainingTitle = this.$store.state.Portal.trainObj.trainingTitleList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingTitle !== undefined ? trainingTitle.text_bn : ''
        } else {
            return trainingTitle !== undefined ? trainingTitle.text_en : ''
        }
      },
      getBatch (id) {
        const data = this.datas.filter(item => item.training_title_id === id)
        return data.length
      },
      calculateColspan (id, monthId) {
        const month = this.datas.filter(item => {
          const date = new Date(item.training_start_date)
          const dateEnd = new Date(item.training_end_date)
          return item.training_title_id === id && (date.getMonth() === monthId || dateEnd.getMonth() === monthId)
        })
        if (month.length === 0) {
          return ''
        }
        const startMonth = new Date(month[0].training_start_date).getMonth()
        const endMonth = new Date(month[0].training_end_date).getMonth()
              return (endMonth - startMonth + 1)
      },
      durationStart (tid, id, monthId) {
          const month = this.datas.filter(item => {
              const date = new Date(item.training_start_date)
              return (item.id === tid && item.training_title_id === id && date.getMonth() === monthId)
          })
          if (month.length === 0) {
              return null
          }
          const startDate = Math.min(...month.map(item => new Date(item.training_start_date).getDate()))
          const startMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_start_date).getMonth() + 1))
          const startMonthData = startMonth ? startMonth.text : null
          return this.$n(startDate) + ' ' + startMonthData
      },
      durationEnd (tid, id, monthId) {
        const month = this.datas.filter(item => {
          const date = new Date(item.training_end_date)
          return (item.id === tid && item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
          return null
        }
        const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        const endMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_end_date).getMonth() + 1))
        const endMonthData = endMonth ? endMonth.text : null
        return this.$n(endDate) + ' ' + endMonthData
      },
      duration (tid, id, monthId) {
        const month = this.datas.filter(item => {
          const date = new Date(item.training_start_date)
          return (item.id === tid && item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
          return null
        }
        const startDate = Math.min(...month.map(item => new Date(item.training_start_date).getDate()))
        const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        const endMonth = this.monthList.find(doc => doc.value === (new Date(month[0].training_end_date).getMonth() + 1))
        const endMonthData = endMonth ? endMonth.text : null
        return this.$n(startDate) + '-' + this.$n(endDate) + ' ' + endMonthData
      },
      duration2 (id, monthId) {
        const month = this.datas.filter(item => {
            const date = new Date(item.training_start_date)
            return (item.training_title_id === id && date.getMonth() === monthId)
        })
        if (month.length === 0) {
            return null
        }
        const startDate = Math.min(...month.map(item => new Date(item.training_start_date).getDate()))
        const endDate = Math.max(...month.map(item => new Date(item.training_end_date).getDate()))
        return this.$n(startDate) + '-' + this.$n(endDate)
      },
      fiscalYearStart () {
        const data = this.fiscalYearList.find(item => item.value === this.search.fiscal_year_id && item.status === 0)
        const fiscalStart = data.text.split('')
        const fiscalStartYear = fiscalStart[2] + fiscalStart[3]
        return fiscalStartYear
      },
      fiscalYearEnd () {
        const data = this.fiscalYearList.find(item => item.value === this.search.fiscal_year_id && item.status === 0)
        const fiscalEnd = data.text.split('')
        const fiscalEndYear = fiscalEnd[7] + fiscalEnd[8]
        return fiscalEndYear
      },
      setResearchServiceUrl (item) {
        const url = '/trainee-trainer-panel/circular-list'
        this.$store.dispatch('Portal/setServiceUrl', {
          externalPanelServiceUrl: url,
          externalPanelCurrentServiceUrl: url
        })
        this.$store.commit('ExternalUserIrrigation/setTrainingPanel', {
          trainingType: 2,
          trainingOrg: this.org_id
        })
        this.$router.push('/auth/login')
      },
      fiscalYearLocale (data) {
        return data.map(item => {
          return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
        })
      },
      fiscalYearBnAdd (data) {
        const bnList = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
        const bnAdded = data.map(item => {
          const fiscalYearData = {}
          const result = item.text_bn.split('').map(item => item === '-' ? '-' : bnList[item])
          fiscalYearData.text_bn = result.join('')
          return Object.assign({}, item, fiscalYearData)
        })
        return bnAdded
      },
      getCurrentFiscalYearId (fiscalYearList) {
          if (fiscalYearList.length === 0) {
            return
          }
          const currentDate = new Date()
          const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
          /** Define the year position  */
          const yearPosition = month < 7 ? 5 : 0
          const yearStr = `${year}`
          let currentFiscalYearId = 0
          fiscalYearList.forEach(element => {
            if (element.text_en.indexOf(yearStr) === yearPosition) {
              currentFiscalYearId = element.value
            }
          })
          return currentFiscalYearId
      },
      async getFiscalYearList () {
          const result = await RestApi.getData(commonServiceBaseUrl, 'fiscal-year-list', null)
          if (result.success) {
            const dataList = this.fiscalYearBnAdd(result.data)
            this.tmpFiscalYearList = dataList
            this.fiscalYearList = this.fiscalYearLocale(dataList)
            this.search.fiscal_year_id = this.getCurrentFiscalYearId(dataList)
            this.searchData()
          }
      },
      async searchData () {
        this.loading = true
        this.showData = true
        if (this.search.fiscal_year_id) {
          const fiscalYear = this.fiscalYearList.find(obj => obj.value === this.search.fiscal_year_id)
          const customItem = {
            fiscal_year_en: fiscalYear?.text_en,
            fiscal_year_bn: fiscalYear?.text_bn
          }
          this.searchHeaderData = Object.assign({}, this.searchHeaderData, customItem)
        }

        const result = await RestApi.getData(trainingElearningServiceBaseUrl, '/portal/training-calender/list', this.search)
        if (result.success) {
          this.loading = false
          if (result.data) {
            this.datas = this.getCustomDataList(result.data)
            this.directorGenerialData = this.getDirectorDataList(result.directorGenerialData.data)
          }
        } else {
          this.datas = []
          this.directorGenerialData = {}
          this.loading = false
        }
      },
      getDirectorDataList (data) {
        // const listData = data.map(item => {
          const degObj = this.$store.state.Portal.commonObj.masterDesignationList.find(doc => doc.value === parseInt(data.designation_id) && doc.status === 0)
          const designationData = {}
          if (typeof degObj !== 'undefined') {
            designationData.designation_name = degObj.text_en
            designationData.designation_name_bn = degObj.text_bn
          } else {
            designationData.designation_name = ''
            designationData.designation_name_bn = ''
          }
          return Object.assign({}, data, designationData)
        // })
        // return listData
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const fiscalYearObj = this.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          return Object.assign({}, item, orgData, fiscalYearData)
        })
        return listData
      }
    }
}
</script>
<style scoped>
  .blink_me {
    animation: blinker 2.5s linear infinite;
    color: #3E69F1;
  }
  @keyframes blinker {
    50% {
      opacity: .09;
      /* background-color: green; */
    }
  }
</style>
